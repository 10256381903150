import React from 'react';
import Image from 'reusecore/src/elements/Image';
import DocumentationSection from '../../../../../containers/SaasModern/Documentation';
import example_year from '../../../../../../../common/src/assets/image/2020/year_month_rows.png';
import template_options_year from '../../../../../../../common/src/assets/image/template_options_year_months_as_rows.png';

const Documentation = () => {
  const content = (
     <div>
       <p>
         The Year (Months as Rows) template style generates a calendar showing an entire year with each month as a row and the days as columns. Each day will be color-coded based on the first event or task occurring
          on that date.
       </p>
       <p>
         <Image alt="" src={example_year} style={{ width: 760 }} />
       </p>
       <h2>Template Settings</h2>
       <p>
         The Year (Months as Rows) template provides the following options when creating a calendar...
       </p>
       <Image alt="" src={template_options_year} style={{ width: 448 }} />
       <br />
       <table className="table">
         <tbody>
         <tr>
            <td>
               <strong>Start month</strong>
            </td>
            <td>The first month to be printed. This can be any month of the year. It doesn't need to start with January.</td>
         </tr>
         <tr>
            <td>
               <strong>First day of week</strong>
            </td>
            <td>
               The first day of the week will be the left-most day column.
            </td>
         </tr>
         </tbody>
       </table>
     </div>
  );

  return (
     <DocumentationSection
        url="https://www.printablecal.com/Documentation/Create-Calendar/Templates-and-Options/Year-Months-As-Rows"
        commentsId="commentsplus_year_months_as_rows"
        title="Year (Months as Rows) Template"
        description="The Year (Months as Rows) template style generates a calendar showing an entire year with each month as a row and the days as columns. Each day will be color-coded based on the first event or task occurring on that date."
        keywords="year template, month columns, 2020 template, calendar template, print 2020, print calendar, print holidays, 2020 holidays, Excel calendar, Word calendar"
        content={content}
     />
  );
};

export default Documentation;
